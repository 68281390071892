<header>
  <gen-lp-header></gen-lp-header>
</header>

<main>
  <span style="display: block;margin: 0 20px 20px 20px; text-align: center;">Klicken Sie auf das entsprechende Symbol, um die HelpCard Ihrem Wallet hinzuzufügen.</span>
  <div *ngIf="!this.vcardBol">
    <img style="margin: auto; margin: 0 0 10px 0; width: 9rem;" (click)="getAndroidWallet()" src="assets/googleIcons/google.svg" alt="">
    <img style="margin: auto;width: 9rem;" (click)="getIosWallet()" src="assets/appleIcons/apple.svg" alt="">
  </div>
  <div *ngIf="this.vcardBol">
    <!--    <button (click)="getvCard()">vCard hinzufügen</button>-->
    <span>{{this.vCardDec.vorname}} {{this.vCardDec.nachname}}</span>
    <span>{{this.vCardDec.akz}}</span>
    <span>{{this.vcard.versicherungsnummer}}</span>
  </div>
</main>
<div *ngIf="loading == true" class="loading" >Content</div>
<footer>
  <div style="text-align: end;
    margin-right: 10px; font-size: 12px"><span>Version: 0.0.10</span></div>
    <gen-footer></gen-footer>
</footer>
