import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'walletLandingPage';

  public loading: boolean = false;
  public vcardBol: boolean = false;
  public vcard: any;
  public vCardDec: any;
  userAgent: string = '';

  data: string = '';
  constructor(
    public activatedRoute: ActivatedRoute,
    public http: HttpClient
  ) {}

  async ngOnInit() {
    this.userAgent = window.navigator.userAgent;
    console.log(this.userAgent);
    const request = this.activatedRoute.queryParams.subscribe(async params => {
      this.data = params['data'];
      this.vcard = params['vcard'];
      console.log(this.data);
      console.log(this.vcard);
      if (this.vcard != undefined) {
        this.vcardBol = true;
        this.getvCard();
      }
      if (this.data != undefined) {
        // await this.decideWithAgent();
      }

    });


  }

  async decideWithAgent() {
    if (this.userAgent.toLowerCase().includes('iphone')) {
      await this.getIosWallet();
    } else if(this.userAgent.toLowerCase().includes('android')) {
      await this.getAndroidWallet();
    }
  }

  async getvCard() {
    this.vCardDec = JSON.parse(atob(this.vcard));
  }

  async getAndroidWallet() {
    this.loading = true;
    console.log('getAndroidWallet called....!', this.data);
    const request = this.http.get(`https://wallet-test.generali-cloud.com/kfz-service-card/create-wallet-android?data=${this.data}`,{responseType: 'text'});
    const result: string = await lastValueFrom(request);
    console.log('result', result);
    this.loading = false;
    window.open(result, "_blank");
  }

  async getIosWallet() {
    console.log('getAndroidWallet called....!');
    // const request = this.http.get(`https://alb.dev.wallet.aws.generali-cloud.com/kfz-service-card/create-wallet-ios?data=${this.data}`,{responseType: 'text'});
    // const result: string = await lastValueFrom(request);
    // console.log('result', result);
    window.open(`https://wallet-test.generali-cloud.com/kfz-service-card/create-wallet-ios?data=${this.data}`, "_blank");
  }
}
